@import '../../../../../styles/colors.scss';

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 16px;
}
.list {
  list-style: none;
  font-size: 12px;
}
.listItem {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 4px 8px;
}
.listItem:nth-child(2n) {
  background-color: $tone-2;
}

@media (max-width: 900px) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 600px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
  .listCaption {
    font-size: 16px;
  }
  .listItem {
    font-size: 14px;
  }
}
