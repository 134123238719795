.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 16px 0;
}
.title {
  font-family: 'Pacifico';
  font-weight: 400;
  font-size: 32px;
  user-select: none;
}
.title:hover {
  cursor: pointer;
}
