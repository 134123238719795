@import '../../../styles/colors.scss';

.wrapper {
  background-color: $tone-1;
  border-radius: 16px;
  padding: 16px;
  position: relative;
}
.title {
  text-align: center;
}
.splitter {
  width: 90%;
  height: 1px;
  background-color: $tone-3;
  margin: 12px 5%;
}
.closeBtn {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 2px;
  cursor: pointer;
  border-radius: 50%;
  background-color: $tone-6;
  transition: .2s;
  z-index: 99;
}
.closeBtn:hover {
  background-color: $brown;
}
