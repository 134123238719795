@import '../../../styles/colors.scss';

.button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: $tone-4;
  border-radius: 50%;
  padding: 8px;
}
.button:hover {
  cursor: pointer;
  background-color: $tone-5;
}
.button:active {
  background-color: $tone-6;
}
