.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loader {
  width: 18px;
  height: 18px;
  border: 2px solid rgb(255, 255, 255);
  border-top: 2px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  animation: rotate .5s linear infinite;
}
.caption {
  text-align: center;
  margin-top: 16px;
  padding: 0 16px;
  font-size: 12px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
