@import '../../../../styles/colors.scss';

.wrapper {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 99;
}
.sortSelect {
  width: 100%;
  height: 22px;
  font-family: 'Montserrat';
  font-size: 12px;
  background-color: $tone-3;
  color: $whitesmoke;
  border: 0;
  border-radius: 4px;
  padding: 2px 4px;
}
.isPricesHighlighted,
.isPhotosBlured,
.isOnlyVideo,
.isOnlyMbrOvr {
  height: 18px;
  border-radius: 4px;
  padding: 2px 4px;
  margin-top: 6px;
}

@media (max-width: 600px) {
  .wrapper {
    height: fit-content;
    width: calc(100% - 16px);
  }
  .sortSelect,
  .isPricesHighlighted,
  .isPhotosBlured,
  .isOnlyVideo,
  .isOnlyMbrOvr {
    height: 28px;
  }
}
