@import '../../../../../styles/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
.list {
  list-style: none;
  font-size: 12px;
}
.commentsAmount {
  color: $smoke;
}
.item {
  padding: 12px 8px;
}
.item:not(:last-child) {
  border-bottom: 1px solid $tone-2;
}
.name {
  display: inline;
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
}
.date {
  display: inline;
  font-family: 'Source Code Pro';
  font-size: 12px;
  font-weight: bold;
  color: $smoke;
}
.text {
  font-size: 12px;
  margin-top: 4px;
}
.highlighted {
  color: $gold;
}

@media (max-width: 600px) {
  .name, .text {
    font-size: 14px;
  }
  .date {
    font-size: 12px;
  }
}
