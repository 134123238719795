@import '../../../../../styles/colors.scss';

.slider {
  min-height: 72vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
}
.slide {
  overflow: hidden;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: $tone-1;
}
.counter {
  font-size: 12px;
  text-align: center;
  margin-top: 4px;
}
.leftArrow, .rightArrow {
  height: 100%;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 32px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  user-select: none;
  z-index: 1;
}
.leftArrow {
  left: 4px;
}
.rightArrow {
  right: 4px;
}

// iPhone 6, 6S, 7, 8, SE 2020 (портретный режим)
@media (orientation: portrait) and (max-device-width: 750px) { 
  .slider {
    height: 61.5vh;
  }
  .counter {
    font-size: 12px;
  }
}
