@import '../../../../styles/colors.scss';

.wrapper {
  height: calc(100% - 16px);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: 8px;
  top: 8px;
}
.wrapper :not(:last-child) {
  margin-bottom: 8px;
}

@media (max-width: 600px) {
  .wrapper {
    height: fit-content;
    position: absolute;
    top: 23px;
  }
  .wrapper :not(:last-child) {
    margin-bottom: 0;
  }
  .top {
    display: flex;
    flex-direction: row;
  }
  .top :not(:last-child) {
    margin-right: 8px;
  }
  .arrowUp {
    position: fixed;
    right: 8px;
    bottom: 8px;
  }
}
