@import '../../../../../styles/colors.scss';

.wrapper {
  display: flex;
  position: relative;
  padding: 8px;
  transition: .3s;
}
.wrapper:hover {
  cursor: pointer;
  background-color: $tone-1;
}
.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.name {
  overflow-wrap: anywhere;
}
.favBtn {
  width: fit-content;
  display: inline-flex;
  border-radius: 8px;
  padding: 4px;
}
.favBtn:hover {
  background-color: $tone-4;
}
.photoContainer {
  min-width: 40%;
  max-width: 40%;
  height: 100%;
  overflow: hidden;
}
.photo {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
  transition: .2s;
}
.phone {
  color: $gold;
  margin-bottom: 4px;
}
.duplicates {
  font-size: 12px;
  margin-bottom: 111px;
}
.equals {
  color: $prime;
  font-size: 12px;
}
.splitter {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: $smoke;
  margin: 0 6px;
  position: relative;
  top: -1.4px;
}
.anthropometry {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 8px;
}
.pricesBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, 44%);
  gap: 4px;
  margin-bottom: 8px;
}
.price {
  background-color: $tone-6;
  text-align: center;
  text-shadow: 0 0 3px $black;
  font-size: 12px;
  padding: 2px 0;
  transition: .2s;
}
.lPrice {
  background-color: $green;
}
.mPrice {
  background-color: $yellow;
}
.hPrice {
  background-color: $brown;
}
.express {
  background-color: $blue;
}
.description {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  scrollbar-width: none;
}
.index {
  font-family: 'Source Code Pro';
  font-size: 10px;
  font-weight: bold;
  background-color: $tone-0;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 2px;
}

@media (max-device-width: 600px) {
  .wrapper {
    transition: none;
  }
  .wrapper:hover {
    background-color: rgba(0, 0, 0, 0);
  }
}
