$black:      rgb(0, 0, 0);
$white:      rgb(255, 255, 255);
$whitesmoke: rgb(200, 200, 200);
$smoke:      rgb(120, 120, 120);

$red:   rgb(120, 20, 20);
$green: rgb(40, 120, 40);
$blue:  rgb(0, 60, 120);

$tone-0: rgb(20, 20, 20);
$tone-1: rgb(26, 26, 26);
$tone-2: rgb(32, 32, 32);
$tone-3: rgb(38, 38, 38);
$tone-4: rgb(44, 44, 44);
$tone-5: rgb(50, 50, 50);
$tone-6: rgb(56, 56, 56);
$tone-7: rgb(62, 62, 62);
$tone-8: rgb(68, 68, 68);
$tone-9: rgb(74, 74, 74);

$prime:  rgb(170, 255, 50);
$gold:   rgb(255, 215, 0);
$brown:  rgb(165, 40, 40);
$yellow: rgb(155, 125, 0);
$sky:    rgb(124,185,232);
