@import '../../../styles/colors.scss';

a {
  color: $prime;
}
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $tone-0;
  padding-bottom: 16px;
}
.errorBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1200px) {
  .profileOverlay {
    align-items: flex-start !important;
  }
}
