@import './styles/colors.scss';
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Medium.ttf');
}
@font-face {
  font-family: 'Pacifico';
  src: url('./fonts/Pacifico-Regular.ttf');
}
@font-face {
  font-family: 'Source Code Pro';
  src: url('./fonts/SourceCodePro-Regular.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: $tone-0;
  color: $whitesmoke;
  font-family: 'Montserrat';
}
.no-scroll {
  overflow: hidden;
}
.blur {
  filter: blur(10px);
}

*::-webkit-scrollbar,
html *::-webkit-scrollbar {
  width: 0;
}
*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
  background-color: $tone-1;
}
*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
  background-color: $tone-4;
}
