@import '../../../styles/colors.scss';

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.caption {
  margin-left: 6px;
  font-size: 12px;
  user-select: none;
}
.switcher {
  position: relative;
  display: inline-block;
  min-width: 24px;
  height: 12px;
}
.switcher input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid $smoke;
  border-radius: 32px;
}
.slider:before {
  position: absolute;
  content: '';
  height: 6px;
  width: 6px;
  left: 1px;
  bottom: 1px;
  background-color: $smoke;
  transition: .080s;
  border-radius: 50%;
}
input:checked + .slider:before {
  transform: translateX(12px);
  background-color: $sky;
}
