.wrapper {
  width: 75vw;
  display: grid;
  justify-content: center;
  gap: 8px;
  grid-template-columns: repeat(auto-fit, 460px);
  grid-auto-rows: 260px;
}

@media (max-width: 900px) {
  .wrapper {
    width: 100vw;
  }
}
@media (max-width: 600px) {
  .wrapper {
    grid-template-columns: repeat(auto-fit, minmax(360px, 100%));
  }
}
