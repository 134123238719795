@import '../../../styles/colors.scss';

.button {
  width: fit-content;
  height: fit-content;
  font-family: 'Montserrat';
  color: white;
  background-color: $tone-5;
  border: 0;
  border-bottom: 2px solid $tone-7;
  border-radius: 24px;
  padding: 4px 12px;
  font-size: 12px;
  user-select: none;
}
.button:hover {
  cursor: pointer;
  background-color: $tone-6;
  border-bottom-color: $tone-8;
}
.button:active {
  transform: translateY(1px);
  border-bottom: 2px solid $tone-6;
}
